@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import 'containers.scss';
@import './components/loader.scss';

body {
	font-family: 'ProximaNova', Arial, Helvetica, sans-serif;
	color: $primaryfontcolor;
	font-size: $basefontsize;
}

@media (min-width: 1400px) {
	.container {
		max-width: 1300px;
	}
}

::-moz-selection {
	/* Code for Firefox */
	color: white;
	background: $primary;
}

::selection {
	color: white;
	background: $primary;
}

h2.c-cirma-title {
	color: $primary;
	font-family: 'Poppins', Sans-serif;
	font-weight: 600;
	font-size: 28px;
}
h3.c-cirma-subtitle {
	color: $primary;
	font-family: 'Poppins', Sans-serif;
	font-weight: 600;
	font-size: 22px;
}

.video-youtube {
	width: 100%;
	aspect-ratio: 16/9;
}

.c-donate {
	font-family: 'Poppins', Arial, Helvetica, sans-serif;
	padding: 2px 5px;
	font-size: 14px;
	margin-left: 10px;
}

.c-search {
	color: white;
	font-weight: bold;

	&:hover {
		color: white;
	}
}

h1 {
	font-size: 45px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 25px;
}

.p-2rem {
	padding: 2.1rem;
}

.text-wrap-pretty {
	text-wrap: pretty;
}

.fw-500 {
	font-weight: 500;
}

.fw-600 {
	font-weight: 600;
}

.fw-700 {
	font-weight: 700;
}

.title-font {
	font-family: 'Poppins', sans-serif, Helvetica;
}

.color-primary {
	color: $primary;
	fill: $primary;
}

.background-primary {
	background-color: $primary;
	color: white;
}

.c-event-card {
	.btn-close {
		color: $primary;
		background: transparent
			url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23831131%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e')
			center/1em auto no-repeat;
	}
}

.c-simple-section {
	position: relative;
	margin-top: 35px;
	margin-bottom: 35px;
}

.c-note-inv {
	color: $primary;
	font-size: 17px;
}

.elementor-widget-container {
	margin-bottom: 20px;
	width: 100%;

	.elementor-divider-separator {
		-webkit-padding-before: 2px !important;
		-webkit-padding-after: 0px !important;
		display: flex;
		width: 20%;
		background-color: #831131;
		height: 1px !important;
		/* padding-block-end: 2px; */
		/* padding-block-start: 2px;
		/* padding-block-start: 2px;
		padding-block-end: 2px; */
	}
}

.c-img-cont {
	width: 100%;
	height: auto;
	overflow: hidden;

	img {
		width: 100%;
		height: auto;
	}

	.style-image {
		cursor: pointer;

		&:hover {
			transform: scale(
				1.1
			); // Adjust the scale factor for the zoom effect
			transition: transform 0.3s ease-in-out;
		}
	}
}

.c-cirma-h3 {
	color: $primary;
	font-family: 'Poppins', Sans-serif;
	font-weight: 600;
}

.c-welcome-scroll {
	fill: #fff;
	color: #fff;
	background-color: #61ce7000;
	border-style: solid;
	border-width: 2px 2px 2px 2px;
	border-radius: 15px 15px 15px 15px;
	padding: 0px 9px 32px 9px;
	position: absolute;
	bottom: 20px;

	&:hover,
	&:focus,
	&:visited {
		color: #fff;
	}

	.scroll {
		position: relative;
		top: 3px;
		width: 3px;
		min-height: 8px;
		display: block;
		background: currentColor;
		border-radius: 5px;
		-webkit-animation: 1.2s ease infinite wheel-up-down;
		animation: 1.2s ease infinite wheel-up-down;
	}
}

@-webkit-keyframes wheel-up-down {
	0% {
		opacity: 0;
	}

	30% {
		opacity: 1;
	}

	100% {
		-webkit-transform: translateY(18px);
		transform: translateY(18px);
		opacity: 0;
	}
}

@keyframes wheel-up-down {
	0% {
		opacity: 0;
	}

	30% {
		opacity: 1;
	}

	100% {
		-webkit-transform: translateY(18px);
		transform: translateY(18px);
		opacity: 0;
	}
}

.c-welcome-cirma {
	font-family: 'Poppins', sans-serif;
	margin-top: 60px !important;

	.c-welcome-cirma-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.c-welcome-title {
		/*font-size: $basefontsize;*/
		font-family: 'Poppins', sans-serif;
		letter-spacing: 2.1px;
		color: $primary;
	}

	.c-cirma-title {
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
		color: $cirmagrisone;
	}

	.c-cirma-desc {
		font-weight: 500;
		/*font-size: $basefontsize;*/
		line-height: 1.85714285714286;
		font-family: 'ProximaNova';
	}

	.c-divisor {
		height: 10px;
		margin-top: 15px;
		margin-bottom: 15px;

		.c-line {
			height: 2px;
			background: $primary;
			width: 40px;
		}
	}

	.c-cirma-btn {
		a {
			font-size: 13px;
			font-weight: 500;
			font-family: 'Poppins', sans-serif;
			text-transform: uppercase;
			padding: 0.75rem, 1rem !important;
			transition: all linear 0.2s;

			&:hover {
				transform: translateY(-5px);
			}
		}
	}
}

.c-cirma-slogan {
	padding-top: 70px;
	padding-bottom: 100px;
	background-color: #f4f4f4;
	display: flex;
	text-align: center;
	justify-content: center;

	.row {
		justify-content: center;
	}

	.c-vertical-line {
		height: 50px;
		margin-bottom: 25px;

		.c-vline {
			width: 2px;
			background-color: $primary;
			height: 100%;
			display: inline-block;
			margin-bottom: 50px;
		}
	}

	.c-slogan-text {
		max-width: 820px;
		font-size: 24px;
		font-weight: 600;
		line-height: 45px;
	}
}

.c-tres-cirma {
	padding-top: 8vh;
	padding-bottom: 4vh;

	.row {
		max-width: 1200px;
	}

	.c-welcome-title {
		/*font-size: $basefontsize;*/
		font-family: 'Poppins', sans-serif;
		letter-spacing: 2.1px;
		color: $primary;
		padding-bottom: 25px;
	}

	.c-cirma-title {
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
		color: $cirmagrisone;
		font-size: 33px;
	}

	.c-cirma-desc {
		font-weight: 500;
		/*font-size: $basefontsize;*/
		line-height: 1.85714285714286;
	}

	.c-divisor {
		height: 10px;
		margin-top: 15px;
		margin-bottom: 15px;

		.c-line {
			height: 2px;
			background: $primary;
			width: 40px;
		}
	}

	.c-img-cont {
		margin-top: 20px;
		transition: transform ease 0.4s;

		& > div {
			text-align: center;
		}

		&.c-fg {
			@media screen and (max-width: 1200px) {
				margin-top: 20px;
			}
			margin-top: 50px;
		}
	}
}

.c-cirma-news {
	background-color: #ffffff;
	display: flex;
	text-align: center;
	justify-content: center;

	.card {
		text-align: left;
		margin-bottom: 10px;
	}

	.row {
		justify-content: center;
	}

	.c-title {
		color: $primary;
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
		margin-bottom: 40px;
	}

	.c-vertical-line {
		height: 50px;
		margin-bottom: 25px;

		.c-vline {
			width: 2px;
			background-color: $primary;
			height: 100%;
			display: inline-block;
			margin-bottom: 50px;
		}
	}
}

.c-cirma-donations {
	color: white;
	background-attachment: fixed;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	margin-bottom: 0;
	.c-overlay {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background: $primarydarker;
		opacity: 0.92;
	}

	.c-letter-wrapper {
		display: flex;
		position: relative;
		text-align: center;
		flex-direction: column;
		flex-wrap: nowrap;
		align-content: center;
		align-items: center;

		.c-img-cont {
			max-width: 26%;
			margin-top: 50px;
			margin-bottom: 10px;
			max-width: 290px;

			& > img {
				aspect-ratio: auto 621 / 610;
			}
		}

		.c-thanks {
			h2 {
				font-family: 'Poppins', sans-serif;
				font-weight: 600;
				margin-bottom: 20px;
			}
		}
	}

	.c-h {
		width: 100%;

		.c-heart-line {
			display: inline-block;
			width: 8%;
			height: 2px;
			background-color: white;
			margin-right: 10px;
			margin-left: 10px;
			margin-bottom: 5px;
			max-width: 87px;
		}

		.c-heart {
			display: inline-block;
			font-size: 30px;
			font-weight: bold;
		}
	}

	.c-forfree {
		font-size: $basefontsize;
		font-weight: 500;
		margin-bottom: 20px;
	}

	.c-donatebtn {
		margin-bottom: 50px;

		a {
			font-size: 13px;
			letter-spacing: 2.3px;
			color: $primary;
			font-family: 'Poppins', sans-serif;
			font-weight: 500;
			padding-left: 15px;
			padding-right: 15px;
			transition: transform linear 0.2s;

			&:hover {
				transform: translateY(-5px);
			}
		}
	}
	.c-cirma-action {
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		justify-content: center;
		color: white;

		.c-r-nl {
			flex-direction: row;
			justify-content: center;

			.c-nolucra {
				text-wrap: balance;
				max-width: 800px;
				font-size: $basefontsize;
				/* line-height: 1.85714285714286; */
				font-weight: 500;
			}
		}

		.c-svs {
			width: 100%;
			max-width: 800px;
			padding-bottom: 20px;

			& > div {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				align-items: center;
			}

			.c-img-cont {
				max-width: 77px;
				margin-bottom: 15px;
				margin-top: 0;
			}

			h3 {
				font-family: 'Poppins', sans-serif;
				font-size: 18px;
				font-weight: 600;
			}
		}
	}
}

.c-cirma-conectado {
	background-color: $cirmagrisconectado;
	margin-bottom: 0;

	.container {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		max-width: 650px;
		padding: 50px;

		.c-mantcont {
			h2 {
				font-family: 'Montserrat', Sans-serif;
				font-size: 34px;
				font-weight: 600;
				margin-bottom: 25px;
			}
		}

		.c-desc {
			line-height: 32px;
			/*font-size: $basefontsize;*/
		}

		.c-col-form {
			width: 100%;
		}

		.c-row-form {
			width: 100%;
		}
	}
}

/* footer {
	background-color: $cirmagrisfooter;
	color: white;
	padding-top: 50px;
	h5 {
		font-weight: 700;
		font-size: 15px;
		font-family: 'ProximaNova', sans-serif;

		text-transform: uppercase;
		letter-spacing: 2.5px;
	}
} */
.c-founders-cirma {
	.c-founders-info {
		display: flex;
		height: 100%;
		align-items: center;
	}

	.c-img-founder {
		display: flex;
		position: relative;
		width: 100%;
		height: 100%;
		flex-direction: column;
	}

	.c-img-background {
		filter: brightness(80%);
	}

	.c-img-info {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 100%;
		height: 100%;
		padding: 20px;
		transition: all 900ms;

		&:hover {
			background: rgba($primary, 0.25);
			transition: all 900ms;
		}
	}

	.c-img-title,
	.c-img-description {
		color: white;
	}

	.c-img-title {
		font-size: 1.5rem;
		font-weight: 700;
		cursor: pointer;
	}

	.c-img-description {
		margin-bottom: 50px;
	}

	.c-biography-button {
		width: 100%;

		button {
			font-size: 13px;
			font-weight: 500;
			font-family: 'Poppins', sans-serif;
			text-transform: uppercase;
			padding: 0.75rem, 1rem !important;
			background-color: rgba($cirmagrisone, 0.001);
			transition: all linear 0.2s;

			&:hover {
				color: white;
				background: rgba($cirmagrisone, 0.15);
				transform: translateY(-5px);
			}
		}
	}
}

.c-hm-cirma {
	.c-hm-historia {
		padding: 30px;
	}
	.c-tres-cirma {
		padding: 0;
		.c-cirma-title {
			color: $primary;
			font-family: 'Poppins', Sans-serif;
			font-weight: 600;
			font-size: 28px;
		}
	}

	.c-divisor {
		.c-line {
			width: 60px;
		}
	}

	.c-hm-info {
		p {
			margin-bottom: 1.6rem;
		}
	}
}

.c-site-cirma {
	.c-site-info {
		display: flex;
		height: 100%;
		width: 100%;
		padding: 0 0 0 50px;
		flex-direction: column;
		justify-content: center;
	}

	.c-site-title {
		text-transform: capitalize;
		color: $primary;
		font-family: 'Poppins', Sans-serif;
		font-weight: 600;
		font-size: 28px;
		margin: 20px 0;
	}

	.c-site-since-info {
		display: flex;
		width: 100%;
		flex-direction: row;

		.c-site-since {
			border-left: 3px solid $primary;
			margin: 20px 0;
			padding-left: 30px;
			font-size: 18px;
			font-weight: 700;
		}
	}

	p {
		margin: 20px 0;
	}

	.c-site-scriptures {
		.c-site-scriptures-describe {
			font-weight: bold;
			font-size: 16px;
			margin: 20px 0;
		}

		.c-site-scriptures-note {
			font-size: 15px;
			margin: 20px 0;
		}
	}

	.c-site-history {
		display: flex;
		width: 100%;
		justify-content: center;

		.c-site-timeline {
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}
}

.c-videos-cirma {
	.c-videos-info {
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: column;
		justify-content: center;

		.c-videos-title {
			color: $primary;
			font-family: 'Poppins', Sans-serif;
			font-weight: 600;
			font-size: 28px;
			margin: 20px 0;
			.c-divisor {
				height: 10px;
				margin-top: calc(15px + 0.5rem);
				.c-line {
					width: 60px;
					height: 2px;
					background: $primary;
				}
			}
		}

		.c-videos-note {
			font-size: 16px;
			margin: 20px 0;
		}

		.c-videos-disclaimer {
			font-size: 14px;
		}
	}

	.c-videos-video-note {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 350px;

		.c-videos-video {
			margin-bottom: 20px;
			overflow: hidden;
			height: 100%;

			.c-video {
				overflow: hidden;
				height: 100%;
				width: 100%;

				.c-play {
					cursor: pointer;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.c-img-cover {
					object-fit: contain !important;
				}
			}
		}

		.c-video-note {
			height: auto;
			text-align: center !important;
			width: 100% !important;
		}
	}

	.c-video-note {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 250px;

		.c-video {
			overflow: hidden;
			margin-bottom: 20px;
			height: 100%;

			.c-play {
				cursor: pointer;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.c-note {
			align-self: center;
		}
	}
}

.c-contact-cirma {
	margin: 0;
	padding: 0;
	background-color: #f7f7f7;

	.c-contact-title {
		color: $primary;
		font-family: 'Poppins', Sans-serif;
		font-weight: 600;
	}

	.c-contact-info {
		display: flex;
		width: 100%;
		height: 100%;
		padding: 90px 50px 70px;
		flex-direction: column;
		justify-content: center;
		background-color: #f5f5f5;
		box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 7%);

		.c-divisor {
			height: 10px;
			margin-top: 15px;
			margin-bottom: 15px;

			.c-line {
				height: 2px;
				background: $primary;
				width: 60px;
			}
		}

		.c-contact-note {
			font-size: 16px;
			overflow-wrap: break-word;
			line-height: 1.85714285714286;

			.c-note {
				margin-bottom: 20px;

				.c-red-note {
					margin-top: 1rem;
					color: $primary;
					font-weight: bold;
				}
			}
		}

		.c-contact-data {
			.c-data-row {
				display: flex;
				margin: 10px 0;
				width: 100%;
				padding: 2px 0;
				align-items: center;

				.c-icon {
					color: $primary;
				}

				.c-data {
					font-size: $smallerfontsize;
					padding-left: 10px;
				}
			}
		}
	}

	.c-contact-form {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		justify-content: center;
		padding: 90px 50px 70px;
		background-color: #ffffff;
	}
}

.c-contact-cirma-map {
	margin: 0;
	padding: 0;
}

.c-research-cirma {
	overflow-wrap: break-word;
	line-height: 1.85714285714286;

	.c-research-info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 100%;

		.c-research-note {
			margin: 20px 0;
		}
		.c-cirma-h3 {
			font-size: 1.1764705882353rem;
		}
	}

	.c-research-image {
		.c-research-note {
			margin-top: 20px;
		}
	}
}

.c-notfound-cirma {
	display: flex;
	width: 100%;
	height: 500px;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.c-notfound {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-family: 'Poppins', Sans-serif;

		.c-cirma-title {
			font-weight: 600;
			color: $primary;
			margin-bottom: 20px;
		}

		.c-cirma-subtitle {
			margin-bottom: 20px;
			font-weight: 600;
			color: $cirmagrisfooter;
		}
	}
}

.c-cirma-social {
	margin-top: 60px;
}

.c-exhibits-cirma {
	.c-exhibits-title {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: center;
		.c-divisor {
			.c-line {
				width: 60px;
				height: 2px;
				background: $primary;
			}
		}
	}

	.c-exhibits-info {
		overflow-wrap: break-word;
		line-height: 1.85714285714286;
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: center;
	}

	.c-row-top {
		margin-bottom: 20px;
	}

	.c-img-cont {
		cursor: pointer;
		height: 100%;
		width: 100%;
		//background-color: black;
		display: flex;
		position: relative;

		img {
			object-fit: contain;
		}

		.c-img {
			display: block;
		}

		.c-img-overlay {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background: rgb(0, 0, 0, 0.6);
			color: white;
			display: flex;
			font-family: 'Poppins', sans-serif;
			align-items: center;
			justify-content: center;
			text-align: center;
			line-height: 30px;
			opacity: 0;

			&:hover {
				opacity: 1;
				transition: all linear 0.2s;
			}
		}

		&:hover {
			transform: translateY(-10px);
			transition: all linear 0.2s;
		}
	}

	/*  .c-slide-background{
        display:block;
        width: 100%;
        height: 100%;
        background: no-repeat 50%;
        -webkit-background-size: cover;
        background-size: cover;
        
    }
    .c-slide-text{
        position:absolute;
        top:0px;
        left:0px;
        display: flex;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: #434142BD;
        transition: all linear 0.2s;
        color: white;

        font-family: "Poppins", sans-serif;
        line-height: 26px;
        //font-size: 17px;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;

        &:hover{
            opacity:1;
        }
    } */
}

.c-publications-cirma {
	.c-publications-title {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: center;
	}

	.c-publications-info {
		padding: 50px;
		overflow-wrap: break-word;
		line-height: 1.85714285714286;
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: center;

		.c-publications-text {
			margin-bottom: 20px;
		}

		.c-publications-button {
			display: flex;
			width: 100%;
			justify-content: flex-start;

			.c-button {
				text-transform: uppercase;
				padding: 7px 24px;
				font-size: 12px;
				font-weight: 500;
				font-family: 'Poppins', Sans-serif;
				transition: all linear 0.2s;

				&:hover {
					color: $primary;
					background: transparent;
					transform: translateY(-5px);
				}
			}
		}

		.c-cirma-h3 {
			margin: 20px 0;
		}
	}

	.c-img-cont {
		cursor: pointer;
	}

	.c-publications-slider {
		display: flex;
		height: 100%;
		align-items: center;

		.c-swiper-cont {
			display: flex;
			justify-content: center;
			width: 100%;
			//height: 30%;
			align-content: center;

			.swiper-button-prev {
				color: $primary;
			}

			.swiper-button-next {
				color: $primary;
			}

			.swiper-pagination {
				margin-top: 1000px;

				.swiper-pagination-bullet {
					background-color: black;
					color: $primary;
				}
			}
		}
	}
}

.c-acknowledgment-cirma {
	.c-acknowledgment-cont {
		padding: 0 10px;
		margin-bottom: 20px;
		width: 100%;
		height: 100%;

		.c-img-cont {
			cursor: pointer;
			transition: all linear 0.2s;

			&:hover {
				transform: translateY(-10px);
			}
		}

		.c-acknowledgment-title {
			display: flex;
			margin-top: 20px;
			color: $primary;
			font-weight: 600;
			width: 100%;
			justify-content: center;
			text-align: center;
		}

		.c-swiper-cont {
			display: flex;
			justify-content: center;
			width: 100%;
			align-content: center;

			.swiper-pagination {
				.swiper-pagination-bullet {
					background-color: black;
				}
			}
		}
	}

	.c-cirma-h3 {
		text-align: center;
		justify-self: center;
		align-items: center;
		margin: 20px 0 40px;
	}
}

.c-donation-cirma {
	.c-img-cont {
		margin: 0 10px 0 0;
	}

	.c-donation-info {
		margin: 0 0 0 10px;
	}

	.c-donation-title-center {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		text-align: center;
		vertical-align: middle;
		margin: 0;

		h5 {
			font-family: 'Poppins', sans-serif;
			font-weight: 600;
			font-size: 18px;
			color: $cirmagrisone;
		}

		h4 {
			margin: 30px 0 20px 0;
			font-family: 'Poppins', sans-serif;
			font-weight: 600;
			font-size: 1.1764705882353rem;
			color: $primary;
		}

		h3 {
			margin: 10px 0;
			font-family: 'Poppins', sans-serif;
			color: $cirmagrisone;
			font-weight: 600;
			font-size: 25px;
		}

		h2 {
			margin: 20px 0;
			font-family: 'Poppins', sans-serif;
			font-weight: 600;
			color: $primary;
		}
	}

	.c-donation-button {
		display: flex;
		width: 100%;
		justify-content: center;

		button {
			display: flex;
			width: auto;
			height: auto;
			justify-content: center;
			gap: 5px;
			align-items: center;
			cursor: pointer;
			transition: all linear 0.2s;
			padding: 8px 24px;

			&:hover {
				transform: translateY(-10px);
			}
		}
	}

	.c-donation-info-center {
		justify-content: center;
		align-items: center;
		text-align: center;
		vertical-align: center;
		font-size: 15px;
		font-weight: 400;
		line-height: 1.6em;
	}
}

@include media-breakpoint-down(md) {
	body {
		font-size: $smallerfontsize;
	}

	.c-donation-cirma {
		.c-img-cont {
			margin: 20px 0 10px 0;
		}

		.c-donation-info {
			margin: 10px 0 20px 0;
		}
	}
}

.contenido {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-around;

	.columna {
		display: flex;
		flex-direction: column;
		width: 45%;
	}
}

.c-content-title {
	color: $primary;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	line-height: 1;
	font-size: 2.5rem;
	margin: 20px 0;
}

.c-content-paragraph {
	margin-bottom: 1.75em;
	font-family: 'ProximaNova', sans-serif;
	line-height: 1.85714285714286;
}

.c-sociales {
	margin: 75px 0;
}

.c-bib,
.c-fg,
.c-ah {
	position: relative;
	display: flex;
	min-width: 270px;

	.c-text {
		color: #fff;
		position: absolute;
		justify-content: center;
		display: flex;
		width: 100%;
		height: 100%;
		font-family: 'Poppins', Sans-serif;
		font-size: 20px;
		font-weight: 600;
		-webkit-text-stroke-color: #000;
		stroke: #000;
		align-items: flex-end;
		transition: all 0.9s;
		text-decoration: none;

		& > * {
			text-align: center;
			justify-content: center;
			margin-bottom: 15px;
		}
	}

	& > *:hover,
	& > *:focus {
		.c-text {
			align-items: center;
			background: rgba(131, 17, 49, 0.75);
			transition: all 0.9s;
		}
	}
}

.c-difusion-head {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 50px 0;
	max-width: 950px;

	.c-divisor {
		height: 10px;
		margin-top: 15px;
		margin-bottom: 15px;

		.c-line {
			height: 2px;
			background: $primary;
			width: 40px;
		}
	}

	.c-cirma-desc {
		font-weight: 500;
		line-height: 1.85714285714286;
	}
}
.c-difusion-item {
	.img-revista {
		width: 210px;
		position: relative;
		box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3),
			0 20px 20px rgba(0, 0, 0, 0.25);
		transform: perspective(500px) rotateY(10deg);
	}
}

.c-btn-goback {
	color: $primary !important;
	margin-right: 15px;

	&:hover {
		transition: all linear 0.3s;

		.c-btn-content {
			transition: all linear 0.3s;
			transform: translateY(-5px);
		}
	}
}

.c-news-updates {
	.c-title {
		font-family: 'Poppins', sans-serif;
		font-style: normal;
		font-weight: 600;
		color: $primary;
		font-size: 23px;
	}
}

.my-masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	margin-left: -30px; /* gutter size offset */
	width: auto;
}
.my-masonry-grid_column {
	padding-left: 30px; /* gutter size */
	background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
	/* change div to reference your elements you put in <Masonry> */
	margin-bottom: 30px;
}

.swiper-events {
	& > .swiper-button-next {
		color: $primary;
		right: 0;
		&::after {
			font-size: 1.5rem;
		}
	}
	& > .swiper-button-prev {
		color: $primary;
		left: 0;
		&::after {
			font-size: 1.5rem;
		}
	}
}

.ReactModal__Overlay {
	z-index: 2000 !important;
}
