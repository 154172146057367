.c-top-nav {
	background-color: rgb(0, 0, 0, 0.38);
	height: 112px;
	transition: all ease 0.4s;
	opacity: 1;
	z-index: 1999;

	.c-nav-cont {
		max-width: 1320px;
		#navbarScroll {
			&.navbar-collapse {
				&.collapse {
					&.show {
						margin-top: 10px;
						.navbar-nav {
							.e1 {
								width: 100%;
								background-color: rgb(0, 0, 0, 0.38);
							}
							.e2 {
								width: 100%;
								background-color: rgb(0, 0, 0, 0.38);
							}
							.e3 {
								width: 100%;
								background-color: rgb(0, 0, 0, 0.38);
							}
							.e4 {
								width: 100%;
								background-color: rgb(0, 0, 0, 0.38);
							}
							.e1,
							.e2,
							.e3,
							.e4 {
								&.dropdown {
									position: inherit;
									.dropdown-menu[data-bs-popper] {
										margin-top: 0;
									}
								}
								.dropdown-menu.show {
									width: 100%;
									left: 0;
									right: 0;
									margin: 0 auto;
									max-width: 1000px;
								}
							}
						}
						.c-nav-form {
							display: flex;
							background-color: rgb(0, 0, 0, 0.38);
							margin-top: -8px;
							padding-bottom: 10px;
						}
					}
				}
			}
		}
	}
	.navbar-brand {
		color: white;
		img {
			height: 76px;
			width: 100px;
		}
	}
	.dropdown-menu.show {
		transition: all linear 0.1s;
	}
	.dropdown-menu[data-bs-popper] {
		margin-top: 2.12rem;
		border-top: 2px solid $primary;
	}

	.navbar-nav {
		.e1 {
			width: 140px;
		}
		.e2 {
			width: 230px;
		}
		.e3 {
			width: 200px;
		}
		.e4 {
			width: 200px;
		}
		.e1,
		.e2,
		.e3,
		.e4 {
			font-family: Montserrat, 'Poppins', sans-serif;
			&.dropdown {
				position: inherit;
				.dropdown-menu[data-bs-popper] {
					margin-top: 0;
				}
			}
			.dropdown-menu.show {
				width: 80%;
				left: 0;
				right: 0;
				margin: 0 auto;
			}
		}
		.e1,
		.e2,
		.e3 {
			.dropdown-menu.show {
				max-width: 1100px;
			}
		}
		.e4 {
			.dropdown-menu.show {
				max-width: 1300px;
			}
		}
		.show {
			& > .nav-link {
				color: white;
			}
		}
		.nav-link {
			color: white;
			transition: all linear 0.1s;
			.droptext {
				padding-left: 12px;
				display: inline-block;
				overflow: visible;
				transition: all linear 0.1s;
				font-size: 14px;
				height: 25px;
			}
			&:hover {
				color: white;
				.dropdown-toggle::after {
					font-size: 20px;
				}
				.droptext {
					padding-left: 0px;
					font-size: 16px;
					padding-left: 5px;
				}
			}
		}
	}

	&.fixed-top,
	&.white {
		background-color: white;
		color: black !important;
		box-shadow: #00000033 0px 1px 2px 0px;
		.c-navbar-toggle {
			border-color: rgba(0, 0, 0, 0.1);
		}
		.navbar-toggler-icon {
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
		}
		#navbarScroll {
			&.navbar-collapse {
				&.collapse {
					&.show {
						background-color: white;
						.c-nav-form {
							display: flex;
							background-color: white;
							padding-bottom: 10px;
							padding-left: 12px;
						}
					}
				}
			}
		}
		.nav-link {
			background-color: white;
			color: black;
			&:hover {
				color: black;
			}
		}
		.navbar-nav {
			.show {
				color: black;
				& > .nav-link {
					color: black;
				}
			}
		}
		.c-nav-form {
			display: flex;
			align-items: center;
		}
		.c-search {
			color: black;
			padding: 0 10px;
			height: 100%;
			display: flex;
			align-items: center;
			margin: 0;
			&.vertical-bar-left {
				border-left: 1px solid black;
				span {
					margin: 0;
					padding: 0;
					height: 18px;
					display: flex;
					align-items: center;
				}
			}
		}
	}
	.c-nav-btn-donate {
		padding: 6px;
		font-family: 'Poppins', sans-serif;
		font-size: 14px;
	}
	.c-nav-form {
		display: flex;
		align-items: center;
	}
}

@include media-breakpoint-down(md) {
	.c-top-nav {
		&.fixed-top {
			position: relative;
		}
	}
}
.offcanvas-backdrop {
	z-index: 2000;
}
.offcanvas {
	z-index: 2001;
	&.show {
		.offcanvas-dropdown {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			width: 100%;
			#navbarScrollingDropdown {
				display: inline-flex;
				gap: 5px;
				align-items: center;
				width: 100%;
				&::after {
					transition: all linear 0.3s;
				}
				&[aria-expanded='true'] {
					&::after {
						transform: rotate(180deg);
						transition: all linear 0.3s;
					}
				}
			}
		}
		.dropdown-menu {
			&.show {
				width: 100%;
				margin-bottom: 7px;
				& > .c-dropdown-item-cont {
					display: flex;
					flex-direction: column;
					padding: 10px;
					gap: 5px;
				}
			}
		}
	}
}
