.c-image-group {
    column-count: 2;

    .c-image-group-item {
        margin: 0;
        display: block;
        grid-template-rows: 1fr auto;
        margin-bottom: 1em;
        break-inside: avoid;
        width: 100%;
    }

    .c-image-group-item-bigger {
        margin: 0;
        display: block;
        grid-template-rows: 1fr auto;
        margin-bottom: 1em;
        break-inside: avoid;
        width: 100%;
    }
}