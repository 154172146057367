.c-main-wrapper{
    &.c-ilay{
        .gv-loading{
            .spac{
                width:100%;
                height: calc(100vh - 300px);
                opacity:1;
                background-color: #f3f3f3;
                background: linear-gradient(to right, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
                -webkit-animation: placeholderShimmer 1s linear 0s infinite normal forwards;
                animation: placeholderShimmer 1s linear 0s infinite normal forwards;
            }
        }
    }
}

.c-loader-item{
    width: 100%;
    height: calc(100vh - 222px);
    opacity:1;
    background-color: #f3f3f3;
    background: linear-gradient(to right, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
    -webkit-animation: placeholderShimmer 1s linear 0s infinite normal forwards;
    animation: placeholderShimmer 1s linear 0s infinite normal forwards;
}