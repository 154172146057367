@import '../../scss/variables.scss';

.mediaLink {
	color: $cirmagrisone;
	text-decoration: none;
	font-size: $basefontsize;
	font-weight: 600;
	line-height: 28px;
	font-family: 'Poppins', sans-serif;
	&.active {
		color: $primary;
	}
	&:hover {
		color: $primary;
	}
	&:focus,
	&:active {
		border: none;
		outline: none;
		box-shadow: none;
	}
}

.mediaCard {
	width: 100%;
	min-width: 100%;
	max-width: 300px;
	position: relative;
	img {
		width: 100%;
		height: auto;
		max-width: 300px;
		object-fit: cover;
	}
	.mediaCardInfo {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
		&:hover {
			cursor: pointer;
			background-color: #840b30eb;
			opacity: 1;
			transform: translate(15px, 15px);
			.mediaCaption {
				opacity: 1;
			}
		}
		.mediaCaption {
			background-color: $primarydarker;
			color: white;
			padding: 10px;
			width: 100%;
			opacity: 0;
			transition: opacity 0.5s ease-in;
			h5,
			p {
				font-size: 17px;
				font-weight: 600;
				font-family: 'Poppins', sans-serif;
				text-align: center;
				text-wrap: balance;
			}
			p {
				font-size: 13px;
				font-family: 'ProximaNova', sans-serif;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.mediaCard {
		max-width: 100%;
		img {
			max-width: 100%;
		}
	}
}
