.c-paragraph-letter-big {
    p {
        &:first-letter {
            float: left;
            font-size: 7em;
            line-height: 1;
            margin-right: .6rem;

        }
    }
}