.c-input-subscribe {
	font-weight: 500;
	width: 100% !important;
	margin-top: 40px;
	margin-bottom: 20px;
	.c-input-s {
		width: 75%;
	}
	.c-subsbtn {
		width: 25%;
		position: relative;
		top: -1px;
	}
}
@include media-breakpoint-down(md) {
	.c-input-subscribe {
		.mb-4 {
			input {
				text-align: center;
			}
		}

		.c-input-s {
			width: 100%;
			text-align: center;
			input {
				text-align: center;
			}
		}
		.c-subsbtn {
			width: 50%;
		}
	}
}
