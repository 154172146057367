.c-soon-message{
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .c-book-soon{
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
}