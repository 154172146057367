@import '../../scss/variables.scss';

.cardClub {
	border: 0;
	text-decoration: none;
	color: black;
	cursor: pointer;
	overflow: hidden;
	&:hover {
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		.cardClubImg {
			transform: scale(1.05);
			transition: transform 0.3s ease-out;
		}
		.cardClubText {
			color: $cirmagrisone;
		}
	}
}

.cardClubImg {
	width: 100%;
	aspect-ratio: 16/9;
	object-fit: cover;
	object-position: center;
	overflow: hidden;
}

.cardClubBody {
	min-height: 220px;
	padding: 30px;
}

.cardClubText {
	//TODO maximum 3 lines
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
