.c-sliding-gallery {
	position: relative;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 15px;

	.swiper-button-next,
	.swiper-button-prev {
		color: white;
	}
	.swiper-pagination-bullet-active {
		background-color: $primary;
	}
	.c-sg-title {
		font-weight: 700;
		font-family: 'Poppins', sans-serif;
		color: $primary;
		margin-bottom: 1rem;
		margin-top: 1rem;
	}
	.c-slide-background {
		display: block;
		width: 100%;
		height: 210px;
		background: no-repeat 50%;
		-webkit-background-size: cover;
		background-size: cover;
	}
	.c-slide-text {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 210px;
		opacity: 0;
		background-color: #434142bd;
		transition: all linear 0.2s;
		color: white;

		font-family: 'Poppins', sans-serif;
		line-height: 26px;
		/*font-size: 17px;*/
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;

		&:hover {
			opacity: 1;
		}
	}
}

@media screen and (min-width: 480px) {
	.swiper-container {
		width: 480px;
	}
}

@media screen and (min-width: 640px) {
	.swiper-container {
		width: 640px;
	}
}

@media screen and (min-width: 768px) {
	.swiper-container {
		width: 768px;
	}
}
@media screen and (min-width: 1024px) {
	.swiper-container {
		width: 1024px;
	}
}
