.c-big-title {
	color: white;
	height: 90vh;
	.c-separator-cont {
		height: 5px;
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;

		.c-bar {
			border-top-color: $primary;
			border-top-width: 5px;
			background-color: $primary;
			height: 5px;
			width: 8%;
			min-width: 60px;
			margin-bottom: 25px;
		}
	}
	.c-bt-cont {
		max-width: 775px;
		text-align: center;
		height: 100%;
		margin: auto;
		display: flex;
		align-items: center;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		& > div {
			display: flex;
		}
		.c-bt-description {
			font-family: 'ProximaNova', Arial, Helvetica, sans-serif;
			font-size: 18px;
			line-height: 16px;
			z-index: 1;
		}
	}
	.c-title {
		font-family: 'Poppins', Sans-serif;
		font-size: 71px;
		font-weight: 400;
		color: white;
		
	z-index: 1;
	}
}

@include media-breakpoint-down(md) {
	.c-big-title {
		min-height: 400px;
		height: auto;
		display: flex;
		flex-direction: column;
		.c-title {
			font-size: 59px;
		}
		.c-bt-cont {
			height: auto;
			max-height: 600px;
		}
	}
}
