.c-title-background{
    height: 250px;
    background-image: url("../../images/portada-art-ritos-rituales.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position:relative;
    background-attachment: fixed;
    .c-bt-cont{
        max-width:680px;
        text-align:center;
        height:100%;
        margin:auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        &>div{
            display:flex;
        }
        .c-bt-description{
            font-family: "ProximaNova",Arial, Helvetica, sans-serif;
            font-size: 18px;
            line-height: 32px;
        }
    }
    .c-title{
        font-family: "Poppins", Sans-serif;
        font-size: 28px;
        font-weight: 600;
        color:white;
    }
    .c-subtitle{
        font-family: "Poppins", Sans-serif;
        font-size: 17px;
        font-weight: 500;
        color:white;
    }

}

@include media-breakpoint-down(md) { 
    .c-big-title{
        min-height:400px;
        height: auto;
        display: flex;
        flex-direction: column;
        .c-title{
            font-size: 59px;
        }
        .c-bt-cont{
            height: auto;
            max-height: 600px;
            
        }
    }
    
 }