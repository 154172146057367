.c-dropdown-menu {
	display: grid;
	/* flex-wrap: wrap;
    justify-content: center; */
	grid-template-columns: repeat(5, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	width: 100%;
	height: 100%;
	/* max-height: 223px !important; */
	row-gap: 21px;
	padding: 10px 15px;
	.c-dropdown-row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		margin: 10px 0;
		justify-content: center;
	}
	.c-dropdown-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
		flex-basis: 1;
		min-width: 100%;
		width: 100%;
		//width: 250px;
		//max-width: 250px;
		height: auto;
		.item-icon-container {
			display: block;
			margin: auto;
			width: 50px;
			height: 50px;
			.item-icon {
				display: flex;
				width: 100%;
				height: 100%;
				justify-content: center;
				align-items: center;
				color: $primary;
				font-size: 2rem;
			}
		}
		.item-text {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			font-family: 'Poppins', Sans-serif;
			font-size: 17px;
			font-weight: 600;
			color: $cirmagrisone;
		}
		&:hover {
			transition: all linear 0.3s;
			.item-icon-container {
				transition: all linear 0.3s;
				transform: translateY(-5px);
			}
		}
		.dropdown-item {
			&:hover {
				background-color: transparent;
			}
			&:active {
				background-color: transparent;
			}
		}
	}
}

@include media-breakpoint-down(lg) {
	.c-dropdown-menu {
		grid-template-columns: repeat(2, 1fr);
	}
}

@include media-breakpoint-down(sm) {
	.c-dropdown-menu {
		grid-template-columns: repeat(1, 1fr);
	}
}
/* @include media-breakpoint-down(lg) {
	.c-dropdown-item {
		width: 20%;
		overflow: auto;
	}
} */

/* @media (min-width: 950px) and (max-width: 1050px) {
    .c-dropdown-item {
		width: 175px !important;
		overflow: auto;
        min-width: unset !important;
	}
}

@media (min-width: 673px) and (max-width: 949px) {
    .c-dropdown-item {
		width: 300px !important;
		overflow: auto;
        min-width: unset !important;
	}
} */
