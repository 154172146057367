.c-input-search {
  width: 100%;
  margin-top: 10px;
  .input-group{
    background-color: white;
    padding: 0.5rem;
  }
  .input-group-text {
    background-color: white;
    font-weight: bolder;
  }
  input {
    height: 48px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 0.5rem;
    // border-right: 0px;
  }
  form {
    width: 100%;
  }
  .opti {
    display: inline-grid;
    color: black;
    text-align: initial;
    // background-color: GhostWhite;
  }
  .opcion {
    padding-left: 0rem;
    height: 15px;
  }
  .selectContainer {
    width: 29%;
    @media screen and (max-width: 480px) {
      width: 40%;
    }
  }
}
#btnSearch {
  background-color: white;
  // border: 1px solid #ced4da;
  border: 0;
  span {
    border: 0;
  }
}
