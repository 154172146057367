.c-last-card {
    width: 100%;
    min-height: 90px;
    display: flex;
    flex-direction: row;
    //align-items: center;

    .c-card-image {
        cursor: pointer;
        width: 100px;
        height: 90px;
        overflow: hidden;
        margin-right: 20px;
    }

    .c-card-image img {
        width: 150%;
        display: block;
    }

    .c-card-content{
        width: 70%;
        .c-card-title{
            cursor: pointer;
        }
    
        .c-card-date{
            color: $secondary !important;
        }
    }
}