footer {
	background-color: $cirmagrisfooter;
	color: white;
	padding-top: 50px;
	min-height: 200px;
	.container {
		max-width: 1320px;
	}
	.c-list-cont {
		margin-bottom: 30px;
	}
	.c-divider {
		background-color: $primary;
		height: 1px;
		display: inline-block;
		width: 25%;
		box-sizing: content-box;
		margin-bottom: 20px;
		margin-top: 12px;
	}
	h5 {
		font-family: 'Montserrat', Sans-serif;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 2.5px;
		margin-bottom: 0px;
	}
	ul {
		padding-left: 0px;
	}
	li {
		list-style-type: none;
		line-height: 1.85714285714286;
		font-family: 'Proxima Nova', sans-serif;
		font-weight: 200;
		font-size: $smallerfontsize - 1px;
		.c-lbl {
			font-family: "'ProximaNova'", Sans-serif;
			font-size: 15px;
			font-weight: 300;
		}
	}
	a {
		text-decoration: none;
		color: white;
		img {
			max-width: 30%;
			margin-right: 10px;
			margin-top: 20px;
			transition-duration: 0.3s;
		}
		img:hover {
			margin-top: 10px;
			margin-bottom: 10px;
		}
		&:focus {
			text-decoration: none;
			color: white;
		}
		&:hover {
			text-decoration: none;
			color: white;
		}
		&:visited {
			text-decoration: none;
			color: white;
		}
	}
	.c-f-icon {
		display: inline-block;
		padding-right: 5px;
		color: $primary;
	}
	.c-social {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		margin-bottom: 20px;
		.c-f-sicon {
			font-size: 30px;
			display: inline-block;

			height: 30px;
			margin-right: 10px;
		}
	}
	.rigths {
		background-color: black;
		height: 4rem;
		padding-top: 1rem;
		color: #b4b4b4;
		text-align: center;
	}
}
