.c-event-card {
	padding-bottom: 12px;
	padding-top: 12px;
	text-align: center;

	.c-card {
		.c-card-body {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.c-card-title {
				color: $primary;
				font-weight: bold;
				font-size: 1.5rem;
			}
			.c-card-text {
				p {
					display: -webkit-box;
					max-width: 100%;
					-webkit-line-clamp: 4;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
			}

			.c-card-date {
				background-color: $primary;
				border-radius: 50%;
				color: white;
				height: 50px;
				width: 50px;
				margin: -40px 0 30px 0;
				font-size: 1.1rem;
				line-height: 100%;
				align-items: center;
				display: flex;
			}
			.c-card-button {
				border: 1px solid $primary;
				color: $primary;
			}
		}
	}
}
