/* span[wbk]:hover{
    text-shadow: 1px 2px 4px rgb(135, 172, 1);
    cursor:pointer;
} */

span[wbk]{
    text-shadow: 0px 0px 0px transparent;
    transition:all ease 0.3s;
}
.wbkeyembed{
    transition: all ease 0.5s;
    position:fixed;
    width:90%;
    height: 90vh;
    background:white;
    border:1px solid #ccc;
    border-radius:5px;
    padding:10px;
    left:30px;
    top:30px;
    bottom:30px;
    right:30px;
    z-index:2000;
    box-shadow: 2px 2px 2px black;
    opacity:1;
    iframe{
        width:100%;
        height:100%;
        border:0px;
    }
    &.closing{
        opacity:0;
        
    }
}

#wbcourtain{
    position:fixed;
    background-color:black;
    opacity:0.4;
    width:100%;
    height:100vh;
    z-index:100;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    z-index: 1999;
    transition:ease all 0.5s;
    &.closing{
        opacity:0;
        
    }
}