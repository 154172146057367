/* fonts */
@import 'fonts.scss';
/*Details */
$enable-rounded: false;

/*colors*/
$primary: #831131;
$primarydarker: #840B30;
$cirmagrisone: #434142;
$cirmagrisfooter: #1E1E1C;
$cirmagrisconectado: #EDEDED;
$primaryfontcolor: #434142;

/*font-sizes*/

$basefontsize: 17px;
$smallerfontsize: 15px;


/*overrides*/
$navbar-dark-toggler-border-color: rgba(#FFFFFF, .1);
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#FFFFFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");


