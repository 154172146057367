.g-vc-wr {
	height: calc(100vh - 250px);
	overflow-y: hidden;
	overflow-x: hidden;
	min-height: 697px;
	width: 100%;
	.g-vw-c {
		box-sizing: border-box;
		position: relative;
		.gpdfv {
			position: relative;
			height: 100%;
			.container {
				& > div {
					background-color: #efefef;
				}
				canvas {
					box-shadow: 2px 2px 2px #afafaf;
				}
			}
			.gpdfv-canvas {
				height: inherit;
			}
		}
	}
}
