.c-top-section {
	//background-image: url("../../images/001-bg-historia.jpg");

	//background-image: url('../../images/portada-home-cirma-1.jpg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	position: relative;
	background-attachment: fixed;
}

.c-top-section-overlay{
	background-color: #000000ED;
    opacity: .43;
	position: absolute;
	height: 100%;
	width: 100%;
}

@include media-breakpoint-down(md) {
	.c-top-section {
		height: auto;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
}

.options_box {
	min-height: 4rem;
	position: absolute;
	z-index: 10;
	font-weight: normal;
}
