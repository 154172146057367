.c-news-card {
	padding-bottom: 12px;
	padding-top: 12px;
	.c-card {
		height: 100%;
		width: 100%;
		overflow: hidden;
		.c-card-image {
			overflow: hidden;
			&.animated {
				display: block;
				background-color: #f3f3f3;
				width: 100%;
				height: 200px;
			}
			max-height: 200px;
		}
		.c-card-body {
			z-index: 100;
			background-color: white;
			padding: 30px;
			//overflow: hidden;
			.c-card-title {
				color: $primary;
				font-family: 'Poppins', Sans-serif;
				font-weight: 600;
				line-height: 1.4;
				font-size: 25px;
			}
			.c-card-subtitle {
				color: $secondary;
				margin-top: 20px;
				margin-bottom: 20px;
				font-family: 'Poppins', Sans-serif;
				font-size: 14px;
				font-weight: 600;
			}
			.c-card-date {
				font-family: 'Poppins', Sans-serif;
				font-weight: 400;
				color: $secondary;
				fill: $secondary;
				margin-top: 5px;
				margin-bottom: 20px;
				display: flex;
				gap: 5px;
				line-height: 1.3em;
				font-size: 12px;
			}
			.c-card-text {
				p {
					display: -webkit-box;
					max-width: 100%;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					font-family: 'ProximaNova', Sans-serif;
					font-size: 17px;
					font-weight: 500;
				}
			}
			.animated {
				opacity: 1;
				background-color: #f3f3f3;
				background: linear-gradient(
					to right,
					#f3f3f3 5%,
					#eee 20%,
					#f3f3f3 35%
				);
				-webkit-animation: placeholderShimmer 1s linear 0s infinite
					normal forwards;
				animation: placeholderShimmer 1s linear 0s infinite normal
					forwards;
			}
		}
		&:hover {
			cursor: pointer;
			transition: all linear 0.3s;
			.c-card-image {
				transition: all linear 0.3s;
				//transform: translateY(-5px);
				transform: scale(1.1);
			}
			.c-card-title {
				transition: all linear 0.3s;
				color: #54595f;
			}
			.c-card-subtitle {
				transition: all linear 0.3s;
				color: #54595f;
				fill: #54595f;
			}
		}
	}
}
