.c-top-header-section {
	display: flex;
	width: 100%;
	justify-content: center;

	.c-top-header-container {
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: $primary;
		align-items: center;
		color: white;
		padding: 50px 0;
		margin: -50px 0 0 0;

		.c-top-header-title {
			display: flex;
			position: relative;
			width: 100%;
			margin: 5px 0;
			justify-content: center;

			h1 {
				font-size: 40px;
				font-weight: 600;
				font-family: 'Poppins', sans-serif;
			}
		}
		.c-divisor {
			height: 10px;
			.c-line {
				height: 1px;
				background: white;
				width: 135px;
			}
		}

		.c-top-header-subtitle {
			margin-bottom: 10px;
			letter-spacing: 2px;
			font-size: 1rem;
			font-weight: 300;
			text-transform: uppercase;
		}
		.c-donation-button {
			background-color: white;
			color: $primary;
			transition: all linear 0.2s;
			margin-top: 5px;
			&:hover {
				transform: translateY(-5px);
			}
		}
	}
	.c-top-header-section-button {
		background-color: $primary;
		display: flex;
		width: 100%;
		justify-content: center;
		.c-top-header-container {
			position: relative;
			display: flex;
			flex-direction: column;
			background-color: $primary;
			align-items: flex-start;
			color: white;
			padding: 50px 50px;
			margin: -50px 0 0 0;
			width: 50%;
			.c-top-header-title {
				display: flex;
				position: relative;
				width: 100%;
				margin: 0 0;
				justify-content: flex-start;

				h1 {
					font-size: 40px;
					font-weight: 600;
					font-family: 'Poppins', sans-serif;
				}
			}
			.c-divisor {
				height: 10px;
				.c-line {
					height: 1px;
					background: white;
					width: 135px;
				}
			}

			.c-top-header-subtitle {
				margin-bottom: 10px;
				letter-spacing: 2px;
				font-size: 1rem;
				font-weight: 300;
				text-transform: uppercase;
			}
		}
		.c-top-header-button {
			position: relative;
			display: flex;
			width: 50%;
			justify-content: flex-end;
			align-items: center;
			padding: 50px 50px;
			margin: -50px 0 0 0;
			background-color: $primary;
			.c-button {
				background-color: white;
				text-transform: uppercase;
				padding: 7px 24px;
				font-size: 12px;
				font-weight: 500;
				font-family: 'Poppins', Sans-serif;
				transition: all linear 0.2s;
				text-decoration: none;
				&:hover {
					color: $primary;
					background: white;
					/* transform: translateY(-5px) !important; */
				}
			}
		}
	}
}

.c-social-media-link {
	color: $primary;
	fill: $primary;
	background-color: white;
	font-size: 19px;
	aspect-ratio: 1/1;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 38px;
	border-radius: 50%;
	transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
	padding: 0;
	text-decoration: none;
	&:hover {
		transform: translateY(-8px);
		opacity: 0.9;
	}
}

.c-social-media-subtitle {
	font-family: 'ProximaNova', sans-serif;
	font-weight: 500px;
	font-size: 17px;
	color: white;
}
