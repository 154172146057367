.timeline-row {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	.timeline-marker {
		margin: 0 20px;
		font-size: 10px;
		display: flex;
		width: auto;
		height: 100%;
		justify-content: center;
		align-items: center;
		position: relative;
		.vertical-line {
			position: absolute;
			width: 1px;
			height: 100%;
			background-color: black;
			transform: translateX(50%);
		}
	}
	.timeline-date {
		display: flex;
		position: relative;
		width: calc(50% - 28px);
		h2 {
			color: #831131;
			font-family: 'Poppins', Sans-serif;
			font-weight: 600;
			font-size: 28px;
			width: auto;
		}
		&.right {
			padding-left: 12px;
			justify-content: flex-start;
			text-align: start;
		}
		&.left {
			padding-right: 12px;
			justify-content: flex-end;
			text-align: end;
		}
	}
	.timeline-box-cont {
		display: flex;
		position: relative;
		width: calc(50% - 40px);
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
		margin: 12.5px 0;
		.timeline-box {
			display: flex;
			padding: 30px 25px;
			width: 100%;
			background-color: #ffffff;
			z-index: 20;
			flex-direction: column;
			&.right {
				align-items: flex-end;
				text-align: end;
			}
			&.left {
				align-items: flex-start;
			}
			&.image {
				flex-direction: row;
				align-items: flex-start;
				.c-img {
					flex-grow: 1;
					max-width: 120px;
					margin-right: 15px;
					.image-right {
						margin-right: 0;
						margin-left: 15px;
					}
				}
				p {
					flex-grow: 5;
				}
			}
		}
		&.right {
			margin-right: 12px;
		}
		&.left {
			margin-left: 12px;
		}
		.arrow {
			position: absolute;
			display: block;
			width: 20px;
			height: 20px;
			top: 50%;
			box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
			transform: translateY(-50%) rotate(45deg);
			background-color: #ffffff;
			background: #ffffff;
			&.right {
				right: -10px;
			}
			&.left {
				left: -10px;
			}
		}
	}
	&.left {
		flex-direction: row-reverse;
	}
	&:first-child {
		.vertical-line {
			position: absolute;
			width: 1px;
			height: 50%;
			background-color: black;
			transform: translateX(50%) translateY(50%);
		}
	}
	&:last-child {
		.vertical-line {
			position: absolute;
			width: 1px;
			height: 50%;
			top: 0;
			background-color: black;
			transform: translateX(50%);
		}
	}
}
