.c-dropdown-item-cont{
    display: flex;
    width: 100%;
    height: auto;
    min-height: 208px;
    padding: 20px 50px;
    align-items: center;
    justify-content: center;
    .c-dropdown-item{
        display: flex;
        flex-grow: 1;
        flex-basis: 1;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        min-width: 280px;
        .c-dropdown-item-title{
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: flex-start;
            .horizontal-line{
                margin-top: 25px !important;
                height: 2px;
                background: $primary;
                width: 20%;
            }
            .c-dropdown-title{
                display: flex;
                margin: 0;
                color: $cirmagrisone;
                font-family: "Poppins", Sans-serif;
                font-size: 30px;
                font-weight: 600;
            }
        }
    }
    
    .dropdown-item{
        padding: 0;
        height: auto;
        width: 100%;
        white-space: normal;
        &:hover{
            background-color: transparent;
        }
        &:active{
            background-color: transparent;
        }
    }
    .c-dropdown-text{
        margin: 0;
    }
}

.c-img-si img {
	// width: 300px;
	// max-height: 250px;
	height: auto;
	width: 100%;
    max-height: 165px;
    max-width: 260px;
  }
  

@include media-breakpoint-down(md) {
	.c-dropdown-item-cont {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
}