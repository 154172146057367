@import '../../scss/variables.scss';

.newsCardImage {
	width: 100%;
	object-fit: cover;
}

.newsCardSubtitle {
	font-family: 'Poppins', Sans-serif;
	color: $cirmagrisone;
	fill: $cirmagrisone;
	line-height: 28px;
	margin-top: 5px;
	margin-bottom: 10px;
	font-size: 1rem;
}

.newsCardTitle {
	color: $primary;
	font-family: 'Poppins', Sans-serif;
	font-weight: 600;
	font-size: 1.5rem;
	margin: 0;
}

.newsCardDate {
	fill: #6c757d;
	color: #6c757d;
	display: flex;
	font-family: Poppins, Sans-serif;
	font-size: 12px;
	font-weight: 400;
	gap: 5px;
	line-height: 1.3em;
	margin-bottom: 20px;
	margin-top: 5px;
}

.newsCardText {
	color: $cirmagrisone;
	font-family: 'Poppins', Sans-serif;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5em;
	margin-bottom: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
}

.newsCard:hover {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	color: #54595f;
	transition: box-shadow 0.3s ease-out;
	.newsCardImage {
		filter: brightness(0.8);
		transition: filter 0.3s ease-out;
	}
	.newsCardSubtitle {
		color: $primary;
		transition: color 0.3s ease-out;
	}
	.newsCardTitle {
		filter: brightness(0.9);
		transition: filer 0.3s ease-out;
	}
	.newsCardDate {
		color: $primary;
		transition: color 0.3s ease-out;
	}
	.newsCardText {
		color: #54595f;
		transition: color 0.3s ease-out;
	}
}
