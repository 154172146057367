.modal-backdrop {
	z-index: 2000 !important;
}
.modal {
	z-index: 2000 !important;
}
.c-bmodal {
	z-index: 2000 !important;
	.c-bmodal-logo {
		height: 76px;
		width: 100px;
	}
	.modal-dialog {
		min-width: 600px !important;
		.modal-content {
			height: 90vh;
			overflow: scroll;
			overflow-x: hidden;
			/* Firefox */
			scrollbar-width: thin;
			scrollbar-color: $primary #cfcfcf;

			/* Chrome, Edge, and Safari */
			&::-webkit-scrollbar,
			*::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-track,
			*::-webkit-scrollbar-track {
				background: #cfcfcf;
			}

			&::-webkit-scrollbar-thumb,
			*::-webkit-scrollbar-thumb {
				background-color: $primary;
				border-radius: 6px;
			}
			.btn-close {
				position: absolute;
				right: 20px;
				top: 20px;
			}
			.modal-header {
				display: flex;
				align-items: center;
				justify-content: center;
				border-bottom: none;
			}
			.modal-footer {
				background-color: $primary !important;
				color: white;
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;
				align-items: center;
				.c-modal-icons {
					display: flex;
					flex-direction: row;
					margin-top: 30px;
					.c-modal-icon {
						margin: 0 5px;
						border-radius: 3px;
						width: 30px;
						height: 30px;
						background-color: white;
						cursor: pointer;
						.c-modal-i {
							color: $primary;
						}
					}
				}
			}
		}
	}
}
