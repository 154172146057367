.c-share-container {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
	.c-share-button {
		margin-right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		&:last-child {
			margin-right: 0;
		}
	}
	.c-share-button-f {
		border-color: #3b5998 !important;
		background-color: #3b5998 !important;
		margin-right: 10px !important;
	}
	.c-share-button-t {
		border-color: #000 !important;
		background-color: #000 !important;
	}

	a {
		text-decoration: none;
		color: white;
		height: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		border-radius: 3px;
		align-self: center;
		font-size: 11px;
		padding: 0 5px;
		gap: 6px;
		margin-right: 10px !important;
		&:hover {
			text-decoration: none;
		}
		&.c-share-button-m {
			border-color: red !important;
			background-color: red !important;
		}
		&.c-share-button-w {
			border-color: #25d366 !important;
			background-color: #25d366 !important;
		}
	}
}
