.c-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	//color: $primary;
	fill: $primary;

	.c-icon {
		color: $primary !important;
		margin-right: 5px;
	}

	.c-author {
		display: flex;
		flex-direction: row;
		margin-right: 10px;
		color: #54595f;
	}

	.c-date {
		display: flex;
		flex-direction: row;
		color: #54595f;
	}

	.c-regresar-button {
		color: $primary !important;
		/*background-color: white !important;
        border: 0px !important; */
		margin-right: 15px;

		&:hover {
			transition: all linear 0.3s;

			.c-button-content {
				transition: all linear 0.3s;
				transform: translateY(-5px);
			}
		}
	}
}
