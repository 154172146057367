.c-bol-card{
    cursor: pointer;
    margin-bottom: 20px;
    .c-bol-dates{
        color: $primary;
        display: flex;
        .c-bol-icon{
            color: $primary;
            margin-right: 10px;
        }
        &:hover{
            color: $secondary;
            .c-bol-icon{
                color: $secondary;
            }
        }
    }
    
    .c-bol-title{
        color: $secondary;
        &:hover{
            color: $primary;
        }
    }

    &:hover{
        .c-bol-title{
            color: $primary;
        }
        .c-bol-dates{
            color: $secondary;
            .c-bol-icon{
                color: $secondary;
            }
        }
    }
}

