.c-modal{
    .modal-content{
        border: none;
    }
    .c-modal-header{
        background-color: $primary;
        color: white;
    }
    .c-modal-title{
        margin: 0;
        padding:0;
        font-family: "Poppins", Sans-serif;
        font-weight: 600;
    }
    .modal-body{
        padding: 25px;
    }
    .c-modal-content{
        padding-bottom: 30px;
    }
    .c-modal-button{
        display: flex;
        width: 100%;
        justify-content: center;
        .c-button{
            text-transform: uppercase;
            padding: 7px 24px;
            font-size: 12px;
            font-weight: 500;
            font-family: "Poppins", Sans-serif;
            transition: all linear 0.2s;
            &:hover{
                color: $primary;
                background: transparent;
                transform: translateY(-5px);
            }
        }
    }
}