.c-top-simple-section{
    /* background-image: url("../../images/backgrounds/header-historia-mision.jpg"); */
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    height:300px;
    position:relative;
    background-attachment: fixed;
    .c-search-cont{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .c-search{
            width: 40%;
        }
    }
}

@include media-breakpoint-down(md) { 

    .c-top-section{
        height: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
 }