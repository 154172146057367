.c-sociales {
	display: grid;
	/* TODO: Al habilitar todas las opciones del menu descomentar siguiente linea */
	// grid-template-columns: repeat(4, 265px);
	grid-template-columns: repeat(3, 265px);
	grid-auto-rows: 1fr;
	grid-gap: 30px;
	place-content: center;
	//justify-content: center;
	@include media-breakpoint-down(lg) {
		grid-template-columns: repeat(3, 1fr);
	}
	@include media-breakpoint-down(md) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include media-breakpoint-down(sm) {
		grid-template-columns: repeat(1, 1fr);
		.c-social-options {
			padding: 0 30px;
		}
	}

	.c-social-options {
		display: flex;
		flex-direction: column;
		align-items: center;
		//justify-content: center;
		flex-wrap: wrap;
		//padding: 30px;

		//margin-top: 1rem;
		.c-social-image {
			width: 120px;
			height: 120px;

			//margin-bottom: 0.5rem;
		}

		.c-social-name {
			color: $primary;
			text-align: center;
		}

		.c-social-description {
			color: $cirmagrisone;
			text-align: center;
			font-family: 'ProximaNova', sans-serif, Arial;
			line-height: 1.6em;
		}

		&:hover {
			cursor: pointer;
			transition: all linear 0.3s;

			.c-social-image {
				filter: invert(13%) sepia(40%) saturate(5260%)
					hue-rotate(327deg) brightness(96%) contrast(100%);
				transition: all linear 0.3s;
				transform: translateY(-5px);
			}
		}
	}
	/* @media (min-width: 992px) {
		flex: 0 0 auto;
		width: 20%;
	} */
}
/* TODO: Al habilitar todas las opciones del menu eliminar codigo */
.c-social-options-flex {
	grid-column: span 3;
	//grid-column: span 4;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 30px;
	.c-social-options {
		display: flex;
		flex-basis: 265px;
		flex-direction: column;
		align-items: center;
		//justify-content: center;
		flex-wrap: wrap;
		//padding: 30px;

		//margin-top: 1rem;
		.c-social-image {
			width: 120px;
			height: 120px;

			//margin-bottom: 0.5rem;
		}

		.c-social-name {
			color: $primary;
			text-align: center;
		}

		.c-social-description {
			color: $cirmagrisone;
			text-align: center;
			font-family: 'ProximaNova', sans-serif, Arial;
			line-height: 1.6em;
		}

		&:hover {
			cursor: pointer;
			transition: all linear 0.3s;

			.c-social-image {
				filter: invert(13%) sepia(40%) saturate(5260%)
					hue-rotate(327deg) brightness(96%) contrast(100%);
				transition: all linear 0.3s;
				transform: translateY(-5px);
			}
		}
	}
}

.c-tooltip {
	.tooltip-inner {
		background-color: $primary !important;
		border-radius: 5px;
		font-size: 1rem;
		max-width: none;
		li::marker {
			margin-right: 10px !important;
		}
		ul {
			text-align: center;
			list-style-position: inside;
			padding-inline-start: 0px;
		}
	}
	.tooltip-arrow {
		filter: invert(13%) sepia(40%) saturate(5260%) hue-rotate(327deg)
			brightness(96%) contrast(100%);
	}
}
