.c-watcher-container{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    .c-exit{
        position: fixed;
        top: 0;
        left: 0;    
        display: flex;
        justify-content: flex-end;
        width: 100%;
        .c-exit-button{
            //margin-top: 10px;
            margin-right: 10px;
            background-color: transparent;
            border: none;
            color: rgb(155, 155, 155);
            font-size: 45px;
            transition: color 0.2s linear;
            font-weight: bold;
            &:hover{
                color: white;
                
            }
        }
    }
    .c-watcher{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
            .c-video{
                width: 70%;
                height: 90%; 
            }
            .c-picture{
                height: auto;
                max-width: 90%;
                max-height: 90%;
            }
    }
}