.c-social-swiper {
	.c-social-title {
		text-align: center;
		color: $primary;
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
		font-size: 20px;
	}
	.c-swiper-slide {
		transition: 0.4s all;
		.c-social-options {
			display: flex;
			justify-content: center;
			align-items: center;
			.c-swiper-name {
				color: white;
				display: none;
				text-align: center;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			img {
				width: 125px;
			}
		}
		&:hover {
			cursor: pointer;
			transition-delay: 0s;
			background-color: $primary;
			img {
				filter: invert(9%) sepia(77%) saturate(4249%) hue-rotate(332deg)
					brightness(96%) contrast(94%);
			}
			.c-swiper-name {
				display: unset;
				color: white;
			}
		}
	}
	.swiper {
		//position: unset !important;
		padding: 0 30px;
		.swiper-button-next {
			right: 5px;
		}
		.swiper-button-prev {
			left: 5px;
		}
		.swiper-button-next::after,
		.swiper-button-prev::after {
			font-size: 20px;
			color: $primary;
			font-weight: 600;
		}
	}
	.swiper-button-next,
	.swiper-button-prev {
		color: $primary;
	}
	.swiper-pagination {
		display: none;
	}
	.swiper-pagination-bullet-active {
		background-color: $primary;
	}
}
