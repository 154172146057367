.cuerpo {
  background-color: #fffaf1;
  margin-top: 3rem;
  .izq {
    @media (max-width: 800px) {
      display: none;
    }
    img {
      max-width: 80%;
      margin-top: -100px;
    }
  }
  .texto {
    font-size: large;
    text-align: center;
  }
  .aliences {
    margin-top: 2rem;
    display: flex;
    @media (max-width: 800px) {
      display: block;
    }
    img {
      max-width: 31%;
      transition-duration: 0.4s;
      height: fit-content;
      cursor: pointer;
      @media (max-width: 800px) {
        max-width: 60%;
        display: block;
        margin: auto;
      }
    }
    img:hover {
      margin-top: -0.5rem;
    }
  }
}
.headerModal {
  text-align: center;
  img {
    max-width: 200px;
    margin-bottom: 1rem;
  }
  div {
    text-align: left;
    padding: 1rem;
    .neg {
      font-weight: bold;
    }
  }
}
